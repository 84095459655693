<template>
  <div class="login container">
    <div class="img-box-md d-block d-md-none">
        <img
            class="img-award-md"
            src="@/assets/images/eu4/Right2x.png"
            alt=""
        />
    </div>
    <div class="login-inner" id="register-page">
      <form
        action="javascript:void(0);"
        method="post"
        class="login-form"
        @submit="do_submit"
      >
        <div class="page-top-nav mb-3" v-if="sysconfig.phoneSupported && sysconfig.emailSupported">
            <li :class="{ active: !emailVCode }">
                <a @click="emailVCode = false" href="javascript:;">{{ $t('register.label_phone') }}</a>
            </li>
            <li :class="{ active: emailVCode }">
                <a @click="emailVCode = true" href="javascript:;">{{ $t('register.label_email') }}</a>
            </li>
        </div>
        <div class="form-group">
          <label>{{ $t('home.register_place_holder') }}</label>
          <template v-if="emailVCode">
              <!-- Email account -->
              <!-- Required by the interface -->
              <input type="hidden" name="RegionCode" value="+1" />
              <input v-model="account" name="Name" type="text" autocomplete="off" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^[^@]+@[^@]{3,}$" class="form-control" :placeholder="$t('register.label_email')" />
          </template>
          <div v-else  class="d-flex">
              <region-selector v-bind:regions="regions" :regionCode="regionCode" @change="onRegionCodeChanged" />
              <div class="flex-fill">
                  <input
                      name="Name"
                      ref="accountInput"
                      v-model="account"
                      pattern="\d*"
                      type="text"
                      class="form-control phone round-right"
                      data-val="true"
                      data-val-required="*"
                      :placeholder="$t('reset_password.label_account')" />
              </div>
          </div>
      </div>

        <div class="form-group">
          <label>{{ $t("reset_password.label_password") }}</label>
          <div class="password-box">
            <input
              name="Password"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              autocomplete="new-password"
              class="form-control"
              data-val="true"
              data-val-required="*"
              :placeholder="$t('reset_password.label_password')"
            />
            <svg
              v-show="!showPassword"
              viewBox="0 0 256 256"
              class="svg-icon password-icon"
              @click="showPassword = !showPassword"
            >
              <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
            </svg>
            <svg
              v-show="showPassword"
              viewBox="0 0 256 256"
              class="svg-icon password-icon"
              @click="showPassword = !showPassword"
            >
              <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
            </svg>
          </div>
        </div>
        <div class="form-group">
          <label>{{ $t("reset_password.label_confirm_password") }}</label>
          <div class="password-box">
            <input
              name="ConfirmPassword"
              v-model="confirmPassword"
              :type="showConfirmPassword ? 'text' : 'password'"
              autocomplete="new-password"
              class="form-control"
              data-val="true"
              data-val-required="*"
              data-val-equalto-other="Password"
              data-val-equalto="*"
              :placeholder="$t('reset_password.label_confirm_password')"
            />
            <svg
              v-show="!showConfirmPassword"
              viewBox="0 0 256 256"
              class="svg-icon password-icon"
              @click="showConfirmPassword = !showConfirmPassword"
            >
              <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
            </svg>
            <svg
              v-show="showConfirmPassword"
              viewBox="0 0 256 256"
              class="svg-icon password-icon"
              @click="showConfirmPassword = !showConfirmPassword"
            >
              <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
            </svg>
          </div>
        </div>
        <sms-verification
          :regionCode="regionCode"
          :account="account"
          action="reset-password"
        />
        <div class="form-group">
          <button
            type="submit"
            class="btn btn-submit"
            :class="{ 'btn-completed': completed, 'btn-loading': submitting }"
            :disabled="submitting || completed"
          >
            {{ $t("reset_password.title") }}
          </button>
        </div>
      </form>
    </div>
    <div class="img img-right">
      <img src="@/assets/images/eu4/Right2x.png" alt="" class="img-award">
    </div>
  </div>
</template>

<script>
import RegionSelector from "../../Components/RegionSelectorV2.vue";
import "@/assets/svg/v4.2/v4.2-hidePassword.svg";
import "@/assets/svg/v4.2/v4.2-showPassword.svg";
export default {
  components: { RegionSelector },
  props: ["regions"],
  data() {
    return {
      emailVCode: false,
      account: null,
      regionCode: null,
      password: null,
      confirmPassword: null,

      showInputs: true,

      submitting: false,
      completed: false,
      showPassword: false,
      showConfirmPassword: false
    };
  },

  mounted() {
    $.resetValidators();
  },
  watch: {
    emailVCode: function () {
        Vue.nextTick(() => {
            $.resetValidators();
        });
    }
  },

  methods: {
    // goNextPage: function (e) {
    //     const frm = $(e.target);
    //     if (frm.valid()) {
    //         this.showInputs = false;
    //     }
    //     return false;
    // },

    // goPrevPage: function () {
    //     this.showInputs = true;
    // },

    do_submit: function (e) {
      const frm = $(e.target);
      const self = this;
      if (self.password !== self.confirmPassword) {
        $.top_error(self.$t("withdraw_password.err_different_passwords"));
        return;
      }
      if (frm.valid()) {
        this.submitting = true;

        const data = frm.serialize();
        $.callPostApi(self, "/api/v1/account/resetpassword", data)
          .then((json) => {
            if (json && json.errcode === 0) {
              // redirect to home page
              self.completed = true;

              setTimeout(function () {
                self.$router.push("/user");
              }, 500);
            } else {
              $.top_error(json.errmsg);
            }
          })
          .catch(function (err) {
            $.top_error(self.$t("general.operation_error"));
          })
          .then(() => {
            self.submitting = false;
          });
      }
    },

    focusAccount: function () {
      this.$refs.accountInput.focus();
    },

    onRegionCodeChanged: function (val) {
      this.regionCode = val;
    }
  }
};
</script>
<style scoped>
.container .img-box-md {
    width: 100%;
    margin-top: -20px;
}
.img-award-md {
    width: 100%;
    height: auto;
}
.login-page .login h1{
    font-size: 26px;
}
.container{
    width: 100%;
    /* height: 100vh; */
    margin: 0;
    max-width: 100%;
    display: flex;
}
.container>div{
    width: 50%;
    height: initial;
    min-width: 540px;
}
.img{
    text-align: center;
    background-color: #0E0637;
}
.img img {
    max-height: calc(100vh - 97px);
}
.right-content{
    background: #F8F9FF;
    position: relative;
}
.img-right{
  display: flex;
}
.img-award{
    height: auto;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
    align-items: center;
}
.form-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 26px;
    color: #000000;
}
.login-page .login .login-inner {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#register-page >.login-form{
    width: 100%;
    min-width: 300px;
    max-width: 700px;
    padding: 0 15%;
}
.page-top-nav{
    display: flex;
    align-items: center;
    margin-bottom: 40px !important;
}
.page-top-nav li{
    width: 50%;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
}
.page-top-nav li.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4.25rem;
    height: .225rem;
    background-color: #5235E8;
}
.page-top-nav li a {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    height: 100%;
}
.page-top-nav li a {
    color: #9ea5b2;
    font-size: 18px;
}
.page-top-nav li.active {
    position: relative;
}
.page-top-nav li.active a {
    color: #5235E8;
    /* border-bottom: 2px solid #041438; */
    font-size: 24px;
    font-weight: 600;
}
.form-label{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #3D3D3D;
}
.page button.btn-submit{
    background: #5235E8;
    border-radius: 8px 8px 8px 8px;
    margin: 40px 0 20px;
}
.text-center a{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #5235E8;
}
.form-control{
    background: #fff;
}
.login-page .login .form-control{
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #C8C8D0;
}
.login-page .login .form-control:focus{
    box-shadow: 0px 0px 12px 0px rgba(82,53,232,0.2);
    border: 1px solid #5235E8;
}
.right-content{
    width: 320px;
    min-height: 550px;
    display: inline-block;
    margin-top: 200px;
    padding: 15px;
}
.right-title{
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #3D3D3D;
    border-bottom: 1px solid #D8D8D8;
    min-height: 40px;
    margin-top: 110px;
}
.right-boby>div{
    text-align: left;
    padding: 2px 10px;
}
.right-boby{
    margin-top: 10px;
}
.right-boby>div:nth-child(2){
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #2C2236;
}
.right-boby>div:nth-child(3){
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #2C2236;
}
.password-box {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
}
.login {
    padding: 0;
    height: 100vh;
}
@media (max-width: 768px) {
    .login-page .login .login-inner{
        justify-content: center;
    }
    .login.container {
        display: block;
    }
    .login-page .login .form-control {
        background: #FAFAFE;
        border: none;
    }
    .login-page .login .form-control:focus {
        border: none;
    }
    #register-page {
        margin-top: -110px;
        background-color: #8C89EB;
        position: relative;
        z-index: 9;
        border-radius: 30px;
    }
    #register-page .login-form {
        background-color: #fff;
        margin-top: 10px;
        border-radius: 30px;
        padding-top: 40px;
        max-width: none;
    }
}
@media (max-width: 1024px) {
    .login-page .login h1{
        margin-top: 0px;
    }
    .img{
        display: none;
    }
    .container>div{
        min-width: 300px;
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
   }
   .login-page .login h1{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 26px;
        color: #3D3D3D;
        padding: 0px;
   }
   .login-page .login .login-inner {
        width: 100%;
   }
}
</style>