<template>
    <div>
        <input v-if="!supportMultipleRegions" type="hidden" name="RegionCode" v-model="localRegionCode" />

        <!-- Need to select regions -->
        <div v-else class="region-container" v-bind:class="{ 'list-open': isOpen }">
            <div class="form-control round-left area-code d-flex flex-row" @click="showSelector">
                <div class="flex-fill">{{ localRegionCode }}</div>
                <i class="dropdown-toggle"></i>
            </div>
            <div class="region-select">
                <div class="input-group search"  @click="searchRegion">
                    <div class="input-group-text">
                        <svg viewBox="0 0 1024 1024" width="32" height="32">
                            <path d="M945.066667 898.133333l-189.866667-189.866666c55.466667-64 87.466667-149.333333 87.466667-241.066667 0-204.8-168.533333-373.333333-373.333334-373.333333S96 264.533333 96 469.333333 264.533333 842.666667 469.333333 842.666667c91.733333 0 174.933333-34.133333 241.066667-87.466667l189.866667 189.866667c6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333c8.533333-12.8 8.533333-34.133333-2.133333-46.933334zM469.333333 778.666667C298.666667 778.666667 160 640 160 469.333333S298.666667 160 469.333333 160 778.666667 298.666667 778.666667 469.333333 640 778.666667 469.333333 778.666667z" fill="#666666" p-id="2868"></path>
                        </svg>
                    </div>
                    <input type="text" class="form-control" v-model="search_term" placeholder="" />
                </div>
                <ul class="region-content">
                    <li v-for="(region, index) in newData" :key="region.areaCode + index" :class="{ active: localRegionCode === region.areaCode }">
                        <label @click="selectRegion">
                            {{ region.name }} <span>{{ region.areaCode }}</span>
                            <input name="RegionCode" v-model="localRegionCode" class="d-none" type="radio" v-bind:value="region.areaCode" />
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* country select */
.region-container {
    min-width: 100px;
    cursor: pointer;
    position: relative;
}
.region-container.list-open .area-code {
    box-shadow: 0px 0px 12px 0px rgba(82,53,232,0.2);
    border: 1px solid #5235E8;
}

.region-container .region-select {
    position: absolute;
    width: 100%;
    left: 0;
    background: #fff;
    -webkit-box-shadow: 0 0 1px 0 rgba(8, 31, 64, 0.31), 0 4px 8px -2px rgba(8, 31, 64, 0.25);
    box-shadow: 0 0 1px 0 rgba(8, 31, 64, 0.31), 0 4px 8px -2px rgba(8, 31, 64, 0.25);
    z-index: -1;
    opacity: 0;
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    min-width: 400px;
    max-height: 30rem;
}
.region-content{
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 25rem;
    min-height: 5rem;
    min-width: 400px;
}
.search .input-group-text{
    width: 45px;
}
.search .form-control:focus{
    box-shadow: 0px 0px 12px 0px rgba(82,53,232,0.2);
    border: 1px solid #5235E8;
}
.search .form-control{
    background: #fff;
}
.region-container.list-open .region-select {
    z-index: 10;
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.region-select li {
    list-style: none;
    cursor: pointer;
    height: 3rem;
    line-height: 3rem;
    padding: 0 1rem;
    white-space: nowrap;
    color: #263241;
}
.region-select li:hover {
    background-color: #f8f9fa;
}
.region-select li.active {
    background-color: rgb(245, 245, 245);
    /* color: rgb(201, 148, 0); */
}
.region-select li label {
    display: block !important;
}
.region-select li label span {
    float: right;
    margin-left: 20px;
    color: #9aa5b5;
}
.login-page .form-control{
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #C8C8D0;
}
@media (max-width: 768px) {
    .region-container .region-select {
        min-width: 300px;
    }
    .region-content{
        min-width: 300px;
        min-height: 5rem;
    }
    .region-container .dropdown-toggle {
        padding-right: 1rem;
    }
    .login-page .form-control {
        background: #FAFAFE;
        border: none;
    }
    .region-container.list-open .area-code {
        border: none;
        background: #FAFAFE;
    }
}
</style>

<script>
export default {
    props: ['regions', 'regionCode'],

    data() {
        return { isOpen: false, localRegionCode: null ,search_term:null,newData:[]};
    },
    watch: {
        localRegionCode: function (newVal, oldVal) {
            this.$emit('change', newVal);
        },
        search_term: function (newVal, oldVal) {
            if(this.regions.length<=0) return;
            if(!newVal) this.newData= this.regions;
            newVal = newVal.replace(/\s*/g,"");
            this.newData = this.regions.filter(item => {
                return item.areaCode.includes(newVal) || item.name.includes(newVal)
            });
        }
    },

    computed: {
        supportMultipleRegions: function () {
            const regions = this.regions || [];
            return regions && Array.isArray(regions) && regions.length > 1;
        }
    },

    created() {
        this.newData= this.regions;
        this.localRegionCode = this.regionCode;
        if (!this.localRegionCode) {
            const regions = this.regions || [];
            if (Array.isArray(regions) && regions.length > 0) {
                this.localRegionCode = regions[0].areaCode;
            } else {
                // Use +86 as the default region code.
                this.localRegionCode = '+86';
            }
        }
    },

    methods: {
        hideSelector: function () {
            console.log(`#### hide region selector.`);
            this.isOpen = false;
            $(window).unbind('click', this.hideSelector);
        },

        showSelector: function (event) {
            event.stopPropagation();
            this.isOpen = true;
            $(window).bind('click', this.hideSelector);
        },

        selectRegion: function (event) {
            event.stopPropagation();
            this.isOpen = false;
            $(window).unbind('click', this.hideSelector);
        },
        searchRegion: function (event) {
            event.stopPropagation();
            this.isOpen = true;
        }
    }
};
</script>
